import React, { useState } from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import GlobalTop from "../../components/globalTop"
import ModalRegister from "../../components/modalRegister"

const Pielegniarstwo = () => {
  const [isRegisterOpen, setIsRegisterOpen] = useState(false)

  return (
    <Layout>
      <SEO seoTitle="Pielęgniarstwo" description="Pielęgniarstwo" />

      <GlobalTop title="Pielęgniarstwo" offer />

      <section className="offer-single-content">
        <div className="container">
          <div className="row flex-column-reverse flex-md-row align-items-center">
            <div className="col-md-6">
              <h2 className="section--header">
                Kim jest
                <strong>pielęgniarka?</strong>
              </h2>
              <p>
                Pielęgniarka to wykwalifikowany pracownik służby zdrowia, który
                zajmuje się opieką nad pacjentami. Odpowiada za monitorowanie
                stanu zdrowia pacjentów, podawanie leków, edukowanie pacjentów i
                ich rodzin w zakresie zdrowia oraz prowadzenie dokumentacji
                medycznej. Pielęgniarki często pełnią również rolę pośrednika
                między pacjentami a innymi członkami zespołu medycznego,
                zapewniając ciągłość opieki i wsparcie emocjonalne dla pacjentów
                oraz ich bliskich. Rola pielęgniarki jest kluczowa w zapewnieniu
                wysokiej jakości opieki zdrowotnej i wsparcia w procesie
                leczenia i rehabilitacji.
              </p>
              <button
                className="btn-custom btn-custom--purple"
                onClick={() => setIsRegisterOpen(true)}
              >
                Umów się
              </button>
            </div>
            <div className="col-md-6">
              <img
                className="img-fluid"
                src={require("../../assets/images/offer/pielegniarstwo/pielegniarstwo-image-1.png")}
                alt=""
              />
            </div>
          </div>
          <div className="divider"></div>
          <div className="row">
            <div className="col-md-6">
              <img
                className="img-fluid"
                src={require("../../assets/images/offer/pielegniarstwo/pielegniarstwo-image-2.png")}
                alt=""
              />
            </div>
            <div className="col-md-5 offset-md-1">
              <h2 className="section--header">
                Kiedy warto skorzystać z usług
                <strong>pielęgniarskich?</strong>
              </h2>
              <p>
                Warto skorzystać z usług pielęgniarki w wielu sytuacjach, w tym
                przy:
              </p>
              <ul>
                <li>
                  Potrzebie oceny stanu zdrowia i monitorowania objawów
                  chorobowych, zwłaszcza jeśli występują nowe lub niepokojące
                  symptomy.
                </li>
                <li>
                  Potrzebie podawania leków, zwłaszcza tych wymagających
                  specjalistycznych umiejętności, takich jak zastrzyki czy
                  cewniki.
                </li>
                <li>
                  Konieczności opieki pooperacyjnej lub po zabiegach medycznych,
                  gdy potrzebne jest profesjonalne wsparcie w gojeniu i
                  zapobieganiu komplikacjom.
                </li>
                <li>
                  Wymaganiu wsparcia w codziennych czynnościach życiowych u osób
                  starszych, niepełnosprawnych lub rekonwalescentów.
                </li>
                <li>
                  Potrzebie edukacji zdrowotnej i wsparcia w zarządzaniu
                  przewlekłymi chorobami, takimi jak cukrzyca czy nadciśnienie.
                </li>
                <li>
                  Chęci uzyskania profesjonalnej porady dotyczącej profilaktyki
                  zdrowotnej, szczepień czy zdrowego stylu życia.
                </li>
              </ul>
              <p>
                <span className="purple-text">
                  Pielęgniarki dostarczają nie tylko wsparcia medycznego, ale
                  też emocjonalnego, co jest niezwykle ważne w procesie leczenia
                  i poprawy jakości życia.
                </span>
              </p>
              <button
                className="btn-custom btn-custom--purple"
                onClick={() => setIsRegisterOpen(true)}
              >
                Umów się
              </button>
            </div>
          </div>
        </div>
      </section>

      <ModalRegister
        isOpen={isRegisterOpen}
        onClose={() => setIsRegisterOpen(false)}
      />
    </Layout>
  )
}

export default Pielegniarstwo
